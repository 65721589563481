<template>
  <b-card>
    <div style="margin: 30px">
      <b-row align-h="around" align-v="center">
        <b-col cols="12" xl="5" lg="5" md="5" sm="5">
          <img height="150px" style="max-width: 100%;" class="mb-2" src="@/assets/images/logo/asa-logo.png" />
        </b-col>

        <b-col cols="12" xl="5" lg="5" md="5" sm="5">
          <h1 class="text-right">{{ $t('home.welcome') }} {{ user.name }}!</h1>
          <p class="text-right" style="color: orange">{{ user.role }}</p>
          <b-card-text class="text-right mt-4"
            >{{ $t('home.welcomeMessage') }}
          </b-card-text>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
}
</script>

<style></style>
